<template>
  <div class="floating-header">
    <a href="http://test.com" class="header-link">
      <img src="@/assets/checkmark.svg" alt="Checkmark" class="checkmark" />
      <h1>PICKYTHING</h1>
    </a>
  </div>
</template>

<script>
export default {
  name: "FloatingHeader",
};
</script>

<style scoped>
.floating-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  background-color: #ffffff; /* Set your desired background color */
  border-bottom: 1px solid #ccc; /* Add a border for separation */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  display: flex;
  align-items: center;
}

.header-link {
  text-decoration: none; /* Remove the default underline for links */
  color: inherit; /* Inherit the text color */
  display: flex;
  align-items: center;
}

.checkmark {
  width: 24px; /* Set the width of the checkmark SVG */
  height: 24px; /* Set the height of the checkmark SVG */
  margin-right: 5px; /* Add some margin for spacing */
  margin-left: 10px;
}
</style>