<template>
  <div class="centered-container">
    <h2 class="promo-heading">
      Having trouble picking a thing? Discover the best thing for your group!
    </h2>

    <!-- Description Box -->
    <div class="content-box">
      <div class="box-heading">
        <h3>What are you trying to pick?</h3>
      </div>
      <div class="box-content">
        <input
          v-model="description"
          type="text"
          placeholder="Enter a description"
          @keyup.enter="finish"
        />
      </div>
    </div>

    <!-- Name Box -->
    <div class="content-box">
      <div class="box-heading">
        <h3>Who are you?</h3>
      </div>
      <div class="box-content">
        <input
          v-model="name"
          type="text"
          placeholder="Enter a name"
          @keyup.enter="finish"
        />
      </div>
    </div>

    <!-- Finish List of Things Button -->
    <button @click="finish" class="finish-button" :disabled="!canFinish">
      Create
    </button>
  </div>
</template>

<script>

export default {
  props: {
    createInstance: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      description: "",
      name: "",
    };
  },
  computed: {
    canFinish() {
      return this.description.trim() !== "" && this.name.trim() !== "";
    },
  },
  methods: {
    async finish() {
      if (this.canFinish) {
        this.createInstance(this.description, this.name);
      }
    },
  },
};
</script>

<style scoped>
</style>
