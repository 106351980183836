<template>
  <div id="app">
    <FloatingHeader />

    <div class="main-content">
      <NewInstance v-if="!instanceId" :createInstance="createInstance" />

      <NameEntry v-if="instanceId && !name && instanceData && instanceData.state.state !== 'final'" :addName="addName" />

      <EnterThings v-if="instanceData && name && instanceData.state.state === 'things'" :instanceData="instanceData" :name="name" :addThing="addThing" :editThing="editThing" :removeThing="removeThing" :updateState="updateState" />

      <RankThings v-if="instanceData && name && instanceData.state.state === 'rank'" :instanceData="instanceData" :name="name" :addRanking="addRanking" />

      <ResultsDisplay v-if="instanceData && instanceData.state.state === 'final'" :instanceData="instanceData" />

      <div v-if="error" class="error">
        {{ error }}
      </div>

      <loading-overlay :loading="loading" />
    </div>
  </div>
</template>

<script>
import FloatingHeader from "@/components/FloatingHeader.vue";
import NewInstance from "@/components/NewInstance.vue";
import NameEntry from "@/components/NameEntry.vue";
import EnterThings from "@/components/EnterThings.vue";
import RankThings from "@/components/RankThings.vue";
import ResultsDisplay from "@/components/ResultsDisplay.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";

export default {
  name: "App",
  components: {
    FloatingHeader,
    NewInstance,
    NameEntry,
    EnterThings,
    RankThings,
    ResultsDisplay,
    LoadingOverlay
  },
  data() {
    //console.log("Data", this);
    return {
      instanceId: null,
      instanceData: null,
      name: null,
      loading: false,
      error: null,
      timeout: null,
      newData: false,
      updates: {}
    };
  },
  mounted() {
    console.log("Mounted", this);
    // Access the dynamic parameter from the path
    const pathParts = window.location.pathname.split("/");
    this.instanceId = pathParts[pathParts.length - 1];

    // If there is an instanceId, make a GET request to retrieve instanceData
    if (this.instanceId && !this.instanceData) {
      this.regularUpdate();
    }

    if (this.instanceId && !this.name)
    {
      const lsName = localStorage.getItem(this.instanceId + "name");
      if (lsName)
      {
        this.name = JSON.parse(lsName);
      }
    }
  },
  methods: {
    async makePostRequest(path, jsonBody, skipLoad) {
      return await this.makeRequest("POST", path, jsonBody, skipLoad);
    },
    async makeRequest(method, path, jsonBody, skipLoad) {
      // Set loading to true during the asynchronous operation
      this.loading = !skipLoad;
      this.error = null;

      try {
        let body = null;
        if (jsonBody)
        {
          body = JSON.stringify(jsonBody);
        }
        // Make an asynchronous POST request
        const response = await fetch("https://pickything.com/" + path, {
          method: method,
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        });

        if (response.ok) {
          // Request was successful, handle the response
          const responseData = await response.json();
          //console.log("POST request successful:", responseData);

          this.loading = false;
          return responseData;
        } else {
          // Request failed, handle the error
          console.error("POST request failed:", response.statusText);
          this.error = response.statusText;
        }
      } catch (error) {
        console.error("Error during POST request:", error);
        this.error = error;
      } finally {
        // Set loading back to false after the asynchronous operation
        this.loading = false;
      }
    },
    async createInstance(description, name) {
      const responseData = await this.makePostRequest("create", { description: description, name: name });

      if (responseData)
      {
        // Set and store name
        this.name = responseData.names[0];
        localStorage.setItem(responseData.id + "name", JSON.stringify(this.name));

        // Update the URL with the new instanceId
        const newPath =
        window.location.pathname.replace(/\/$/, "") + `/${responseData.id}`;
        window.history.pushState({}, "", newPath);

        this.instanceId = responseData.id;
        this.instanceData = responseData;

        this.newData = true;
        this.regularUpdate();
      }
    },
    async fetchInstanceData(skipLoad) {
      const responseData = await this.makeRequest("GET", "instance/" + this.instanceId, null, skipLoad);
      this.instanceData = responseData;
      this.newData = true;
    },
    async regularUpdate() {
      if(!this.newData && (this.instanceData ? this.updates[this.instanceData.lastUpdate] : 0) < 100)
      {
        await this.fetchInstanceData(true);
      }
      this.newData = false;
      this.updates[this.instanceData.lastUpdate] = (this.updates[this.instanceData.lastUpdate] || 0) + 1;
      if (this.instanceData.state.state !== "final")
      {
        this.timeout = setTimeout(this.regularUpdate, 5000);
      }
    },
    async addName(name) {
      const responseData = await this.makePostRequest("instance/" + this.instanceId + "/addName", { name: name });

      // Set and store name
      this.name = responseData;
      localStorage.setItem(this.instanceId + "name", JSON.stringify(this.name));
      await this.fetchInstanceData();
    },
    async addThing(newThing) {
      const responseData = await this.makePostRequest("instance/" + this.instanceId + "/addThing", { thing: newThing, addedBy: this.name.id});

      this.instanceData = responseData;
      this.newData = true;
    },
    async editThing(thingId, newThing) {
      const responseData = await this.makePostRequest("instance/" + this.instanceId + "/editThing", { id: thingId, thing: newThing });

      this.instanceData = responseData;
      this.newData = true;
    },
    async removeThing(thingId) {
      const responseData = await this.makePostRequest("instance/" + this.instanceId + "/removeThing", { id: thingId });

      this.instanceData = responseData;
      this.newData = true;
    },
    async updateState() {
      const responseData = await this.makePostRequest("instance/" + this.instanceId + "/updateState", { id: this.name.id });

      this.instanceData = responseData;
      this.newData = true;
    },
    async addRanking(rankedThings, rejectedThings) {
      const responseData = await this.makePostRequest("instance/" + this.instanceId + "/addRanking", { id: this.name.id, rankedThings: rankedThings, rejectedThings: rejectedThings });

      this.instanceData = responseData;
      this.newData = true;
    },
  },
};
</script>

<style>
#app {
  font-family: Roboto, sans-serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.centered-container {
  max-width: 600px;
  margin: 50px auto;
  text-align: center;
}

.sub-heading {
  font-weight: bold;
  margin-top: 10px;
}

.content-box {
  margin-top: 20px;
  border: none;
  /* Remove border */
  background-color: #fff;
  /* White background color */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  /* Slight shadow */
  width: 100%;
  display: inline-block;
}

.box-heading {
  padding: 10px;
  background-color: #3498db;
  /* Blue background color */
  color: #fff;
  /* White text color */
  text-align: left;
  margin-top: 0;
}

.box-content {
  padding: 15px;
  margin-top: 0;
}

.input-container {
  margin-top: 10px;
}

input {
  width: calc(80% - 20px);
  padding: 8px;
  border: 1px solid #ccc;
  /* Add a subtle border for input fields */
}

button {
  margin-left: 10px;
  padding: 10px;
  background-color: #3498db;
  /* Blue button color */
  color: #fff;
  /* White text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.state {
  padding: 5px;
  background-color: #3498db;
  /* Blue button color */
  color: #fff;
  /* White text color */
  border: none;
  border-radius: 3px;
}

.actions button {
  padding: 5px;
  border-radius: 3px;
}

.actions img {
  height:24px;
  width: 24px;
}
.finish-button {
  margin-top: 20px;
}

.finish-button:disabled {
  background-color: #ccc;
  /* Use a different background color for disabled state */
  cursor: not-allowed;
  /* Change cursor to not-allowed for disabled state */
}

.main-content {
  margin-top: 60px;
  padding: 20px;
}

.error {
  margin-top: 10px;
}

.promo-heading {
  font-style: italic;
  font-weight: normal;
}

.name-list {
  margin-top:10px;
}

.name-list span {
  padding-right:5px;
}

</style>

