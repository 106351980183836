<template>
  <div class="centered-container">
    <h2 class="promo-heading">
      Here are the best things for your group in ranked order.
    </h2>

    <h2 class="sub-heading">{{ instanceData.description }}</h2>

    <!-- List of Things Box -->
    <div class="content-box">
      <div class="box-heading">
        <h3>The Best Things</h3>
      </div>
      <div class="box-content">
        <ul>
          <li v-for="thing in bestThings" :key="thing.id">
            <div class="list-item">
              <span><span class="rank">{{ thing.rank }}</span>{{ thing.thing }}</span>
              <span class="score">{{ thing.score }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="content-box">
      <div class="box-heading">
        <h3>Not Interested Things</h3>
      </div>
      <div class="box-content">
        <ul>
          <li v-for="thing in rejectThings" :key="thing.id">
            <div class="list-item">
              <span>{{ thing.thing }}</span>
              <span class="score">{{ thing.score }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    instanceData: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      bestThings: [],
      rejectThings: [],
    };
  },
  mounted() {
    this.getRankings(this.instanceData);
  },
  watch: {
    'instanceData': {
      handler(newInstanceData) {
        this.getRankings(newInstanceData);
      },
      deep: true, // This is required to watch for changes inside the array
    },
  },
  methods: {
    getRankings(instanceData) {
      let allRejectedThings = [];

      // Step 1: Create a map to store opinions for each thing
      let thingOpinions = {};

      // Step 2: Loop through each name and calculate their opinions
      instanceData.names.forEach((nameObj) => {
        const rankedThings = instanceData.rankings[nameObj.id].rankedThings;
        const rejectedThings = instanceData.rankings[nameObj.id].rejectedThings;

        //console.log("Ranked Things for " + nameObj.name + ":", rankedThings);
        //console.log("Rejected Things for " + nameObj.name + ":", rejectedThings);

        // Calculate weighted opinions for ranked things
        rankedThings.forEach((thing, index) => {
          const weight = 1 / (index + 1); // Weight based on ranking position
          thingOpinions[thing.id] = (thingOpinions[thing.id] || 0) + weight;
        });

        // Add rejected things to list
        rejectedThings.forEach((thing) => {
          if (!allRejectedThings.includes(thing.id)) {
            allRejectedThings.push(thing.id);
          }
        });
      });

      //console.log("Thing Opinions:", thingOpinions);

      //console.log("All Rejected Things:", allRejectedThings);

      // Step 3: Tally up the opinions and find the top-rated things
      const sortedThingIds = Object.keys(thingOpinions).sort(
        (a, b) => thingOpinions[b] - thingOpinions[a]
      );

      //console.log("Sorted Things:", sortedThingIds);

      this.bestThings = [];
      this.rejectThings = [];
      let currentRank = 1;
      let currentScore = thingOpinions[sortedThingIds[0]];
      sortedThingIds.forEach((thingId) => {
        for (const thing of instanceData.things) {
          if (thing.id === thingId) {
            if (allRejectedThings.includes(thing.id)) {
              thing.score = thingOpinions[thing.id].toFixed(2);
              this.rejectThings.push(thing);
            }
            else {
              if (thingOpinions[thing.id] !== currentScore) {
                currentRank++;
                currentScore = thing.score
              }
              thing.score = thingOpinions[thing.id].toFixed(2);
              thing.rank = currentRank;
              this.bestThings.push(thing);
            }
            break;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.rank {
  padding: 5px;
  background-color: #3498db;
  /* Blue button color */
  color: #fff;
  /* White text color */
  border: none;
  border-radius: 3px;
  margin-right: 10px;
  font-weight: bold;
}

.score {
  padding: 5px;
  background-color: #3498db;
  /* Blue button color */
  color: #fff;
  /* White text color */
  border: none;
  border-radius: 3px;
}
</style>
