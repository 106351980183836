<template>
  <div class="centered-container">
    <h2 class="promo-heading" v-if="instanceData.createdBy === name.id">
      Share this link with your group either before or after adding things.
    </h2>
    <h2 class="promo-heading">
      Add things to pick from.
    </h2>

    <h2 class="sub-heading">{{instanceData.description}}</h2>

    <!-- List of Things Box -->
    <div class="content-box">
      <div class="box-heading">
        <h3>Things</h3>
      </div>
      <div class="box-content">
        <ul>
          <li v-for="thing in instanceData.things" :key="thing.id">
            <div class="list-item">
              <span>{{ thing.thing }}</span>
              <span class="actions">
                <button @click="editHandler(thing)">Edit</button>
                <button @click="deleteHandler(thing)">Delete</button>
              </span>
            </div>
          </li>
        </ul>
        <div class="input-container">
          <input
            v-model="newThing"
            type="text"
            placeholder="Enter a thing"
            @keyup.enter="addHandler"
          />
          <button @click="addHandler">Add</button>
        </div>
      </div>
    </div>

    <button @click="finishList" class="finish-button" :disabled="!canFinish">Done adding things</button>

    <ParticipantList :instanceData="instanceData" />
  </div>
</template>

<script>
import ParticipantList from "@/components/ParticipantList.vue";

export default {
  components: {
    ParticipantList
  },
  props: {
    instanceData: {
      type: Object,
      default: null
    },
    name: {
      type: Object,
      default: null
    },
    addThing: {
      type: Function,
      default: null
    },
    editThing: {
      type: Function,
      default: null
    },
    removeThing: {
      type: Function,
      default: null
    },
    updateState: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      newThing: "",
      movedOn: false,
    };
  },
  mounted() {
    this.updateNameArrays(this.instanceData);
  },
  watch: {
    'instanceData': {
      handler(newInstanceData) {
        this.updateNameArrays(newInstanceData);
      },
      deep: true, // This is required to watch for changes inside the array
    },
  },
  computed: {
    canFinish() {
      return this.instanceData.things.length >= 2 && !this.movedOn;
    },
  },
  methods: {
    updateNameArrays(instanceData) {
      this.movedOn = instanceData.state.moveOn.includes(this.name.id);
    },
    async addHandler() {
      if (this.newThing.trim() !== "") {
        await this.addThing(this.newThing.trim());
        this.newThing = ""; // Clear the input field
      }
    },
    async editHandler(thing) {
      const editedThing = prompt("Edit the thing:", thing.thing);
      if (editedThing !== null) {
        await this.editThing(thing.id, editedThing.trim());
      }
    },
    async deleteHandler(thing) {
      let addedBy;
      for(const forName of this.instanceData.names)
      {
        if (forName.id === thing.addedBy)
        {
          addedBy = forName;
          break;
        }
      }
      const confirmDelete = confirm("Are you sure you want to remove " + thing.thing + " added by " + addedBy.name + "?");
      if (confirmDelete) {
        await this.removeThing(thing.id);
      }
    },
    async finishList() {
      if (this.canFinish) {
        await this.updateState();
      }
    }
  }
};
</script>

<style scoped>

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

</style>
