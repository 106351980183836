<template>
  <div class="centered-container">
    <h2 class="promo-heading">
      Rank the things or indicate you are not interested in them.
    </h2>

    <h2 class="sub-heading">{{ instanceData.description }}</h2>

    <!-- Rank Things Box -->
    <div class="content-box">
      <div class="box-heading">
        <h3>Ranked Things</h3>
      </div>
      <div class="box-content">
        <!-- Draggable list of things -->
        <draggable v-model="rankedThings" group="things" @start="drag=true" @end="drag=false" :disabled="movedOn" item-key="id" filter=".actions" animation="100">
          <template #item="{element, index}">
            <div class="draggable-item">
              <span class="rank-thing"><span class="rank">{{ index + 1 }}</span> {{ element.thing }}</span>
              <span v-if="!movedOn" class="actions">
                <button @click="increaseRank(element.id)" :disabled="index === 0"><img src="@/assets/arrowup.svg" alt="Increase Rank"/></button>
                <button @click="decreaseRank(element.id)"><img src="@/assets/arrowdown.svg" alt="Decrease Rank" /></button>
              </span>
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <!-- Rejected Things Box -->
    <div class="content-box">
      <div class="box-heading">
        <h3>Not Interested Things (max {{ this.maxRejects }})</h3>
      </div>
      <div class="box-content">
        <draggable v-model="rejectedThings" group="things" @start="drag=true" @end="drag=false" :disabled="movedOn" item-key="id" filter=".actions" animation="100">
          <template #item="{element}">
            <div class="draggable-item">
              <span class="rank-thing">{{ element.thing }}</span>
              <span v-if="!movedOn" class="actions">
                <button @click="unrejectThing(element.id)"><img src="@/assets/arrowup.svg" alt="Increase Rank" /></button>
              </span>
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <button @click="finishRank" class="finish-button" :disabled="!canFinish">
      Done ranking things
    </button>

    <ParticipantList :instanceData="instanceData" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import ParticipantList from "@/components/ParticipantList.vue";

export default {
  components: {
    draggable,
    ParticipantList
  },
  props: {
    instanceData: {
      type: Object,
      default: null
    },
    name: {
      type: Object,
      default: null
    },
    addRanking: {
      type: Function,
      default: null
    },
  },
  data() {
    return {
      drag: false,
      rankedThings: [...this.instanceData.things],
      rejectedThings: [],
      maxRejects: 0,
      movedOn: false,
    };
  },
  mounted() {
    this.updateLists(this.instanceData);
  },
  watch: {
    'instanceData': {
      handler(newInstanceData) {
        this.updateLists(newInstanceData);
      },
      deep: true, // This is required to watch for changes inside the array
    },
  },
  computed: {
    canFinish() {
      return this.rejectedThings.length <= this.maxRejects && !this.movedOn;
    },
  },
  methods: {
    updateLists(instanceData) {
      if (instanceData.rankings[this.name.id])
      {
        console.log("Ranking:", instanceData.rankings[this.name.id]);
        this.rankedThings = [...instanceData.rankings[this.name.id].rankedThings];
        this.rejectedThings = [...instanceData.rankings[this.name.id].rejectedThings];
      }

      this.maxRejects = Math.floor((instanceData.things.length - 1) / instanceData.names.length);

      this.movedOn = instanceData.state.moveOn.includes(this.name.id);
    },
    rejectThing(thingId) {
      const rejectedThing = this.findAndRemoveThing(this.rankedThings, thingId);
      if (rejectedThing) {
        this.rejectedThings.push(rejectedThing);
      }
    },
    unrejectThing(thingId) {
      const unrejectedThing = this.findAndRemoveThing(this.rejectedThings, thingId);
      if (unrejectedThing) {
        this.rankedThings.push(unrejectedThing);
      }
    },
    increaseRank(thingId) {
      const index = this.rankedThings.findIndex(thing => thing.id === thingId);
      if (index === 0)
      {
        return;
      }
      const temp = this.rankedThings[index];
      this.rankedThings.splice(index, 1);
      this.rankedThings.splice(index-1, 0, temp);
    },
    decreaseRank(thingId) {
      const index = this.rankedThings.findIndex(thing => thing.id === thingId);
      if (index === this.rankedThings.length - 1)
      {
        this.rejectThing(thingId);
        return;
      }
      const temp = this.rankedThings[index];
      this.rankedThings.splice(index, 1);
      this.rankedThings.splice(index+1, 0, temp);
    },
    findAndRemoveThing(array, thingId) {
      const index = array.findIndex(thing => thing.id === thingId);
      if (index !== -1) {
        return array.splice(index, 1)[0];
      }
      return null;
    },
    async finishRank() {
      if (this.canFinish) {
        await this.addRanking(this.rankedThings, this.rejectedThings);
      }
    }
  },
};
</script>

<style scoped>

/* Add some styles for the draggable items */
.draggable-item {
  padding: 5px;
  margin: 5px 0;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  cursor: grab;
  display: flex;
  justify-content: space-between;
}

/* Add styling for the dragging state */
.draggable-dragging {
  opacity: 0.5;
}

.rank-thing {
  line-height: 34px;
  vertical-align: middle;
  margin-left:10px;
}

.rank {
  padding: 5px;
  background-color: #3498db;
  /* Blue button color */
  color: #fff;
  /* White text color */
  border: none;
  border-radius: 3px;
  font-weight: bold;
}

.disabled {
  opacity: 0.1;
  cursor: grab;
}
</style>