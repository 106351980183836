<template>
    <div class="content-box">
      <div class="box-heading">
        <h3>Participants</h3>
      </div>
      <div class="box-content">
        <ul>
          <li v-for="name in instanceData.names" :key="name.id">
            <div class="list-item">
              <span>{{ name.name }}</span>
              <span v-if="instanceData.state.moveOn.includes(name.id)" class="state">Done</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>

export default {
  props: {
    instanceData: {
      type: Object,
      default: null
    },
  }
};
</script>

<style scoped>

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

</style>
